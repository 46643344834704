import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Layout, Header } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Container, Wrapper } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/gene.jpg";

export const MaladieLhfPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <SEO title={title} />
      <Header title={title} bgImage={bgImage} fallback={linearGradients.maladieLhf} />
      <Wrapper>
        <Container>
          <PageContent className="markdown" content={content} />
        </Container>
      </Wrapper>
    </>
  );
};

MaladieLhfPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const MaladieLhfPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = post;

  return (
    <Layout>
      <MaladieLhfPageTemplate title={frontmatter.title} contentComponent={HTMLContent} content={post.html} />
    </Layout>
  );
};

MaladieLhfPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MaladieLhfPage;

export const nousConnaitrePageQuery = graphql`
  query MaladieLhfPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
